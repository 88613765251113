import * as React from 'react';
import Banner from '../../Components/Banner/Banner';
import Layout from '../../Components/Layout/Layout';
import Button from '../../Components/Button/Button';

import { graphql, useStaticQuery } from 'gatsby';

import { getSectionSortedObject } from '../../utils/getSectionSortedObject/getSectionSortedObject';

import { PortableText } from '@portabletext/react';
import { client } from '../../utils/postData/postData';

import './styles.scss';
import PaymentModal from '../../Components/PaymentModal/PaymentModal';
import PayPalButton from '../../Components/PayPalButton/PayPalButton';
const initialState = {};
function reducer(state, action) {
  const newState = { ...state, ...action };
  return newState;
}

// markup
const AlumniPage = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const data = useStaticQuery(graphql`
    query alumniQuery {
      allSanityAlumni {
        nodes {
          year
          name
          expiration
        }
      }
      allSanityCorner {
        nodes {
          golfDate
          alumniForm {
            asset {
              url
            }
          }
          _rawMailingAddress
        }
      }
      allSanityPositions {
        nodes {
          alumniVicePresident
          alumniTreasurer
          alumniPresident
        }
      }
    }
  `);
  const [amount, setAmount] = React.useState(10);
  const [golfAmount, setGolfAmount] = React.useState(100);
  const alumni = data?.allSanityAlumni?.nodes;
  const positions = data?.allSanityPositions?.nodes?.[0];
  const date = new Date(data?.allSanityCorner?.nodes?.[0]?.golfDate);
  date.setDate(date.getDate() + 1);
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const golfDate = date.toLocaleDateString('en-US', options);
  const alumniToDate = alumni.filter((item) => {
    const date = new Date(item.expiration);
    const now = new Date();
    return !item.expiration || date >= now;
  });
  const sortedAlumni = getSectionSortedObject(alumniToDate, 'year'); // Object with keys of years containing alumni of that year
  const alumniSections = Object.keys(sortedAlumni).reverse();
  function construct(arr) {
    const temp = [];
    for (let i = 0; i < arr.length; i += 2) {
      const year1 = arr[i];
      if (i + 1 < arr.length) {
        const year2 = arr[i + 1];
        temp.push([year1, year2]);
      } else {
        temp.push([year1]);
      }
    }
    return temp;
  }
  const years2dArray = React.useMemo(
    () => construct(alumniSections),
    [alumniSections]
  );

  const alumniForm = data?.allSanityCorner?.nodes?.[0]?.alumniForm?.asset?.url;
  const mailAddress = data?.allSanityCorner?.nodes?.[0]?._rawMailingAddress;
  console.log(alumniForm);
  return (
    <Layout className='Alumni'>
      <Banner title='Alumni' />
      <section className='Background-White'>
        <div className='Frame-Inner Grid Grid-L Grid-1 Grid-Gap-2 Text-Center'>
          <div className='Column-Center'>
            <h2>Show Your Support</h2>
            <p>
              Donate to become recognized as an alumni supporter for either $10
              for a year or $100 for life.
            </p>
          </div>
          <div className='Column-Center'>
            <PaymentModal
              openButtonText='Become Alumni Supporter'
              buttonClassName='Button-Blue'
            >
              <div className='Alumni-Mail'>
                <h2>Become an Alumni Supporter!</h2>
                <p>
                  To become an Alumni please print out and fill out the form and
                  mail in to the following address with your money
                </p>
                <Button
                  onClick={alumniForm}
                  buttonText='Alumni Form'
                  external
                />
                <div className='Contact'>
                  {mailAddress && (
                    <div className='MailingAddress'>
                      <PortableText value={mailAddress} />
                    </div>
                  )}
                  <div>
                    <p>Reach out if you have any questions </p>
                    <a href='tel:9375724790'>937-572-4790</a>
                  </div>
                </div>
              </div>
              {/* {state?.alumniSuccess ? (
                <div>Thank you for your support!</div>
              ) : (
                <>
                  <h2>Become an alumni supporter!</h2>
                  <form className='Alumni-Form'>
                    <label>
                      Full Name<span className='Required-Field'>*</span>
                      <input
                        className='name'
                        type='text'
                        placeholder='John Smith'
                        onChange={(e) => {
                          dispatch({ alumniName: e.target.value });
                        }}
                        value={state.alumniName ?? ''}
                      />
                    </label>
                    <label>
                      Graduation Year<span className='Required-Field'>*</span>
                      <input
                        className='year'
                        type='number'
                        placeholder={new Date().getFullYear()}
                        onChange={(e) => {
                          dispatch({ alumniYear: e.target.value });
                        }}
                        value={state.alumniYear ?? ''}
                      />
                    </label>
                    <label>
                      Donation Amount
                      <select
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                      >
                        <option value={10}>$10 (for the year)</option>
                        <option value={100}>$100 (lifetime)</option>
                      </select>
                    </label>
                  </form>
                  {state.alumniName && state.alumniYear && (
                    <PayPalButton
                      amount={amount}
                      onApprove={async (amount) => {
                        const obj = {
                          name: document.querySelector('.Alumni-Form .name'),
                          year: document.querySelector('.Alumni-Form .year'),
                        };
                        for (const key in obj) {
                          const element = obj[key];
                          obj[key] = element.value;
                        }
                        const query =
                          '*[_type == "alumni"] {_id, name, year, expiration}';
                        let id;
                        await client.fetch(query).then((alumnis) => {
                          alumnis.forEach((alumni) => {
                            if (alumni.name === obj.name) {
                              id = alumni._id;
                            }
                          });
                        });
                        const date = new Date();
                        date.setFullYear(date.getFullYear() + 1);
                        if (id) {
                          client
                            .patch(id) // Document ID to patch
                            .set({
                              year: obj.year,
                            })
                            .set({
                              expiration:
                                amount === 10
                                  ? date.toISOString().substring(0, 10)
                                  : undefined,
                            }) // Shallow merge
                            .commit() // Perform the patch and return a promise
                            .then((updatedBike) => {
                              console.log(
                                'Hurray, the alumni is updated! New document:'
                              );
                              console.log(updatedBike);
                            })
                            .catch((err) => {
                              console.error(
                                'Oh no, the update failed: ',
                                err.message
                              );
                            });
                        } else {
                          const doc = {
                            _type: 'alumni',
                            name: obj.name,
                            year: obj.year,
                            expiration:
                              amount === 10
                                ? date.toISOString().substring(0, 10)
                                : undefined,
                          };

                          client.create(doc).then((res) => {
                            console.log(
                              `Alumni was created, document ID is ${res._id}`
                            );
                          });
                        }
                        dispatch({ alumniSuccess: true });
                      }}
                      onError={() => {
                        alert('An error has occured please try again');
                      }}
                    />
                  )}
                </>
              )} */}
            </PaymentModal>
          </div>
        </div>
      </section>
      <section className='Background-Blue Contact-Section'>
        <div className='Smaller-Frame Grid Grid-L Grid-1 Grid-Gap-4 Grid-Center'>
          <div className='Text-Center'>
            <h2>Golf Outing RSVP</h2>
            <p>
              Once a year we have a golf outing in spirit of Brookville
              Wrestling.{' '}
              {golfDate
                ? `This year's golf outing will be held on ${golfDate}.`
                : ''}{' '}
              We will email people who signed up more details as the event
              approaches.
            </p>
          </div>
          <div>
            <PaymentModal
              openButtonText='Sign Up'
              buttonClassName='Button-White'
            >
              {state?.golfSuccess ? (
                <div>Thank you for your support!</div>
              ) : (
                <>
                  <h2>Golf Outing RSVP</h2>
                  <form className='Golfer-Form'>
                    <label>
                      Full Name<span className='Required-Field'>*</span>
                      <input
                        required
                        className='name'
                        type='text'
                        placeholder='John Smith'
                        onChange={(e) => {
                          dispatch({ golfName: e.target.value });
                        }}
                        value={state.golfName ?? ''}
                      />
                    </label>
                    <label>
                      Phone Number<span className='Required-Field'>*</span>
                      <input
                        required
                        className='phone'
                        type='text'
                        placeholder='555-555-5555'
                        onChange={(e) => {
                          dispatch({ golfNumber: e.target.value });
                        }}
                        value={state.golfNumber ?? ''}
                      />
                    </label>
                    <label>
                      Email<span className='Required-Field'>*</span>
                      <input
                        required
                        className='email'
                        type='text'
                        placeholder='JohnSmith@email.com'
                        onChange={(e) => {
                          dispatch({ golfEmail: e.target.value });
                        }}
                        value={state.golfEmail ?? ''}
                      />
                    </label>
                    <label>
                      Type<span className='Required-Field'>*</span>
                      <select
                        required
                        className='type'
                        onChange={(e) => {
                          setGolfAmount(Number(e.target.value));
                        }}
                      >
                        <option value={60}>Individual ($60)</option>
                        <option value={240}>Team of 4 ($240)</option>
                        <option value={100}>Hole Sponsor ($100)</option>
                      </select>
                    </label>
                    <Button
                      disabled={
                        !(
                          state.golfName &&
                          state.golfNumber &&
                          state.golfEmail &&
                          golfAmount
                        )
                      }
                      submit
                      onClick={async () => {
                        const obj = {
                          name: document.querySelector('.Golfer-Form .name'),
                          phone: document.querySelector('.Golfer-Form .phone'),
                          email: document.querySelector('.Golfer-Form .email'),
                        };
                        for (const key in obj) {
                          const element = obj[key];
                          obj[key] = element.value;
                        }
                        let type;
                        if (golfAmount === 100) {
                          type = 'sponsor';
                        } else if (golfAmount === 240) {
                          type = 'team';
                        } else {
                          type = 'individual';
                        }
                        const doc = {
                          _type: 'golfer',
                          name: obj.name,
                          phone: obj.phone,
                          email: obj.email,
                          type: type,
                        };

                        client.create(doc).then((res) => {
                          console.log(
                            `Golfer was created, document ID is ${res._id}`
                          );
                        });

                        dispatch({ golfSuccess: true });
                      }}
                      buttonText='RSVP'
                    />
                  </form>
                  {/* {state.golfName && state.golfNumber && state.golfEmail && (
                    <PayPalButton
                      amount={golfAmount}
                      onApprove={async () => {
                        const obj = {
                          name: document.querySelector('.Golfer-Form .name'),
                          phone: document.querySelector('.Golfer-Form .phone'),
                          email: document.querySelector('.Golfer-Form .email'),
                        };
                        for (const key in obj) {
                          const element = obj[key];
                          obj[key] = element.value;
                        }
                        let type;
                        if (golfAmount === 100) {
                          type = 'sponsor';
                        } else if (golfAmount === 240) {
                          type = 'team';
                        } else {
                          type = 'individual';
                        }
                        const doc = {
                          _type: 'golfer',
                          name: obj.name,
                          phone: obj.phone,
                          email: obj.email,
                          type: type,
                        };

                        client.create(doc).then((res) => {
                          console.log(
                            `Golfer was created, document ID is ${res._id}`
                          );
                        });

                        dispatch({ golfSuccess: true });
                      }}
                      onError={() => {}}
                    />
                  )} */}
                </>
              )}
            </PaymentModal>
          </div>
        </div>
      </section>
      <section className='Background-White Alumni-Board'>
        <div className='Frame-Inner Grid Grid-L Grid-1 Grid-Gap-2'>
          <div>
            <h2>Alumni Board</h2>
          </div>
          <div className='Grid Grid-L Grid-1-1-1 Grid-Gap-4'>
            {positions?.alumniPresident && (
              <div>
                <div className='Position'>President</div>
                <div>{positions?.alumniPresident}</div>
              </div>
            )}
            {positions?.alumniVicePresident && (
              <div>
                <div className='Position'>Vice President</div>
                <div>{positions?.alumniVicePresident}</div>
              </div>
            )}
            {positions?.alumniTreasurer && (
              <div>
                <div className='Position'>Treasurer</div>
                <div>{positions?.alumniTreasurer}</div>
              </div>
            )}
          </div>
        </div>
      </section>
      {years2dArray?.length && (
        <section className='Background-Blue Alumni-Section'>
          <div className='Frame-Inner Grid Grid-L Grid-1 Grid-Gap-2'>
            <div>
              <h2>Alumni Supporters</h2>
            </div>
            <div className='Grid Grid-L Grid-1-1 Grid-Gap-4'>
              {years2dArray.map((section, index) => (
                <div key={index} className='Grid Grid-XS Grid-1-1 Grid-Gap-4'>
                  {section.map((year) => (
                    <div key={year}>
                      <h3>{year}</h3>
                      <ul>
                        {sortedAlumni[year].map((alumni) => (
                          <li key={index + alumni.name}>{alumni.name}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
};

export default AlumniPage;
